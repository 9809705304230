/**
 * Ensures user is authenticated
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const tokenStore = useTokenStore();
    const { token } = storeToRefs(tokenStore);
    const { hash } = storeToRefs(useLocalSettingsStore());

    if (isGuestPage(to)) return;

    // Check Token
    const tokenExpired = tokenStore.tokenExpired();
    const refreshTokenExpired = tokenStore.refreshTokenExpired();

    if (!token.value || (tokenExpired && refreshTokenExpired)) {
        if (!hash.value) {
            return showError({
                statusCode: 404,
            });
        }

        return navigateTo({
            name: "hash-login",
            params: {
                hash: hash.value,
            },
            ...(!is404Page(to) && { query: { redirect: to.fullPath } }),
        });
    } else if (tokenExpired && !refreshTokenExpired) {
        try {
            await tokenStore.refresh();
        } catch {
            tokenStore.setToken({});

            if (!hash.value) {
                return showError({
                    statusCode: 404,
                });
            }

            return navigateTo({
                path: `/${hash.value}/login`,
                ...(!is404Page(to) && { query: { redirect: to.fullPath } }),
            });
        }
    }

    // Check User
    const userStore = useUserStore();
    const { isError } = storeToRefs(userStore);
    await userStore.suspense();
    if (isError.value) {
        return showError({
            statusCode: 404,
        });
    }
});

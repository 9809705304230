import { verify } from "@/lib/api/verify";

export const useUserStore = defineStore("userStore", () => {
    const { token } = storeToRefs(useTokenStore());

    const query = useQuery({
        queryKey: ["smake-use.productionApi.app.verify", token],
        queryFn: verify,
        select: (data) => data,
        retry: false,
        enabled: computed(() => !!token.value),
    });
    return query;
});

import axios from "axios";

export const useLocalSettingsStore = defineStore("localSettings", () => {
    const config = useRuntimeConfig();
    const settings = useLocalStorage("localSettings-settings", {
        locale: config.public.locale,
        hash: undefined as string | undefined,
    });

    if (!settings.value.hash) {
        settings.value.hash = undefined;
    }

    watchEffect(() => {
        axios.defaults.headers.common["Accept-Language"] =
            settings.value.locale;
    });

    return toRefs(settings.value);
});

export default defineNuxtRouteMiddleware((to) => {
    if (to.matched.length !== 0) return;

    const router = useRouter();
    const { hash } = storeToRefs(useLocalSettingsStore());

    if (hash.value && !to.fullPath.startsWith(`/${hash.value}`)) {
        const newPath = `/${hash.value}${to.fullPath}`;
        const routeLocation = router.resolve(newPath);

        if (routeLocation.matched.length !== 0) return navigateTo(newPath);
    }
});

import revive_payload_client_4sVQNw7RlN from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/productions.smake.cloud/releases/v0.1.5/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_jg3TSEXw60 from "/home/forge/productions.smake.cloud/releases/v0.1.5/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import _01_flare_client_77FFoSnclP from "/home/forge/productions.smake.cloud/releases/v0.1.5/plugins/01.flare.client.ts";
import _02_font_awesome_client_AeZHygCfx8 from "/home/forge/productions.smake.cloud/releases/v0.1.5/plugins/02.font-awesome.client.ts";
import _03_font_awesome_kit_client_GlOdMnv1z4 from "/home/forge/productions.smake.cloud/releases/v0.1.5/plugins/03.font-awesome-kit.client.ts";
import _04_i18n_client_AHZthUNqLK from "/home/forge/productions.smake.cloud/releases/v0.1.5/plugins/04.i18n.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_jg3TSEXw60,
  _01_flare_client_77FFoSnclP,
  _02_font_awesome_client_AeZHygCfx8,
  _03_font_awesome_kit_client_GlOdMnv1z4,
  _04_i18n_client_AHZthUNqLK
]
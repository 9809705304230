import { default as dashboardLboEoKirQQMeta } from "/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/dashboard.vue?macro=true";
import { default as entrancePMl6AihY1vMeta } from "/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/entrance.vue?macro=true";
import { default as index5ecXPUJHYiMeta } from "/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/index.vue?macro=true";
import { default as loginRRCVCKnjZjMeta } from "/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/login.vue?macro=true";
export default [
  {
    name: "hash-dashboard",
    path: "/:hash()/dashboard",
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entrancePMl6AihY1vMeta?.name ?? "hash-entrance",
    path: "/:hash()/entrance",
    meta: entrancePMl6AihY1vMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "hash",
    path: "/:hash()",
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: loginRRCVCKnjZjMeta?.name ?? "hash-login",
    path: "/:hash()/login",
    meta: loginRRCVCKnjZjMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v0.1.5/pages/[hash]/login.vue").then(m => m.default || m)
  }
]
export default defineNuxtRouteMiddleware((to) => {
    const pathArray = to.fullPath.toString().split("/");

    if (pathArray.length < 2) {
        return showError({
            statusCode: 404,
        });
    }

    const { hash } = storeToRefs(useLocalSettingsStore());

    hash.value = pathArray[1];
});
